<template>
  <form @submit="onSubmit">
    <fieldset class="gap-y-2 flex items-center flex-col">
      <legend class="text-secondary-700">{{ $t('currentPhoneNumber', { value }) }}</legend>
      <TextInput
        id="phone"
        type="tel"
        name="phone"
        class="w-full text-base text-primary-A900"
        :required="true"
        :label="$t('phoneNumber')"
      >
        <template #hint>
          <span class="text-secondary-700">
            {{ $t('phoneNumberHint') }}
          </span>
        </template>
      </TextInput>
      <AppButton :loading="isSubmitting" type="submit" class="mt-11 w-1/2 mx-auto">{{ $t('save') }}</AppButton>
    </fieldset>
  </form>
</template>
<script setup lang="ts">
import * as yup from 'yup';

const { t: $t } = useI18n({
  useScope: 'local',
});

const props = defineProps<{
  value: string;
}>();

const { handleSubmit, isSubmitting } = useForm({
  validationSchema: toTypedSchema(
    yup.object({
      phone: yup.string().required(),
    }),
  ),
  initialValues: {
    phone: props.value,
  },
});

const emit = defineEmits<{
  (e: 'submit', phone: string): void;
}>();

const onSubmit = handleSubmit(({ phone }) => {
  emit('submit', phone);
  console.log(phone);
});
</script>
<i18n>
{
    "en": {
        "save": "Change Phone Number",
        "phoneNumber": "Phone Number",
        "currentPhoneNumber": "Current Phone Number: {value}",
        "phoneNumberHint": "An SMS message will be sent to this number for verfication"
    },
    "ar": {
        "save": "تغيير رقم الهاتف",
        "phoneNumber": "رقم الهاتف",
        "currentPhoneNumber": "رقم الهاتف الحالي: {value}",
        "phoneNumberHint": "سيتم إرسال رسالة نصية قصيرة إلى هذا الرقم للتحقق"
    }
}
</i18n>
