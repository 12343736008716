<template>
  <div
    class="text-black flex flex-col relative"
    :class="{
      'items-center': alignment === 'center',
      'items-start': alignment === 'start',
    }"
  >
    <div v-if="state === 'ENTER_CODE'">
      <MainTitle el="header">
        <template #before>
          <span class="text-1.6xl lg:text-2.6xl">
            {{ $t('title', { type: $t(type) }) }}
          </span>
        </template>
      </MainTitle>

      <p v-purify="$t('sentTo', { identity: mask ? mask : identity })" class="mt-4 text-secondary-700" />

      <div v-if="!disableChangePhoneNumber && type === 'phone'" class="mt-4">
        <span class="text-secondary-700">{{ $t('notYourPhone') }}</span>

        <button class="underline inline font-normal text-primary-800 ml-3" @click="state = 'UPDATE_IDENTITY'">
          {{ $t('changePhoneNumber') }}
        </button>
      </div>

      <div class="relative">
        <form
          dir="ltr"
          class="otp-input-container mt-9 grid grid-cols-4 gap-4 xs:gap-7 pb-8 mx-auto"
          :class="{ 'mx-auto': alignment === 'center' }"
          @keyup="handleMoveToNextInput"
          @keydown="handleInvalidNumberKeys"
        >
          <input
            v-for="(_, input) in inputs"
            :key="input"
            v-model="inputs[input]"
            class="transparent-selection bg-transparent outline-none text-center caret-transparent rounded-3xl [ h-25 md:h-35 w-full ] [ flex items-center justify-center ] [ lg:text-2.5xl ]"
            :class="{
              ' border border-[#DDDFEA] focus:border-tertiary-700 focus:placeholder-tertiary-700 text-primary-700 font-bold':
                !(!touchedOtp && invalidOtp),
              'border border-red-700 text-red-700': !touchedOtp && invalidOtp,
            }"
            data-max-length="1"
            placeholder="_"
            type="number"
            :disabled="isVerifyingOtp"
            :aria-disabled="isVerifyingOtp"
            @input="touchedOtp = true"
            @focus="handleFocusOtpInput"
          />
        </form>
        <p v-if="!touchedOtp && invalidOtp" class="w-full text-center text-red-700 absolute bottom-0 left-0 text-sm">
          {{ $t('invalidOtp') }}
        </p>
      </div>

      <div class="flex justify-center text-secondary-700">
        {{ $t('didntReceiveCode') }}

        <template v-if="isSendingVerificationCode || isVerifyingOtp">
          <Spinner class="mx-auto w-6 h-6" />
        </template>
        <button
          v-else
          type="button"
          class="underline text-primary-800 font-normal ml-3"
          :disabled="!!activeTimer"
          :aria-disabled="!!activeTimer"
          @click="handleResendCode"
        >
          {{ $t('resendCode') }}
          <span v-if="activeTimer">({{ `${activeTimer} ${$t('secondsLeft')}` }})</span>
        </button>
      </div>
    </div>

    <div v-if="state === 'UPDATE_IDENTITY'">
      <MainTitle el="header">
        <template #before>
          <span class="text-1.6xl lg:text-2.6xl">
            {{ $t('changePhoneNumber') }}
          </span>
        </template>
      </MainTitle>

      <p v-purify="$t('changePhoneNumberDescription')" class="mt-4 text-base text-center text-secondary-700" />

      <EditPhoneNumber :value="identity" class="mt-4" @submit="onEditPhoneNumberSubmit" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { CombinedError } from 'villus';

type State = 'ENTER_CODE' | 'UPDATE_IDENTITY';
defineComponent({
  /**
   * emits:
   * identity: the value represents the updated value triggered from update phone number ( phone number only )
   */
  name: 'VerifyOtp',
});

const props = defineProps({
  /**
   * @description the identity string could be an email|phone number to be verified
   */
  value: {
    type: String,
    required: true,
  },
  disableChangePhoneNumber: {
    type: Boolean,
    default: false,
  },
  validationOnly: { type: Boolean, default: false },
  mask: { type: String, default: '' },

  alignment: {
    type: String,
    required: false,
    validator: (value: string) => {
      return ['center', 'start'].includes(value);
    },
    default: 'start',
  },
});

const emit = defineEmits(['success', 'error', 'close']);

const identity = ref(props.value);
const state = ref<State>('ENTER_CODE');

const touchedOtp = ref(false);
const invalidOtp = ref(false);

const { reSendVerificationCode, isFetching: isSendingVerificationCode } = useSendVerificationCode(identity.value);
const { inputs, phoneVerification } = usePhoneVerification();
const { error, success } = useAlerts();
const { t: $t } = useI18n({
  useScope: 'local',
});

const { t } = useI18n();

const { ExceptionsMap } = useExceptions('otpVerification');

const { verifyOtp, isFetching: isVerifyingOtp } = useVerifyOtp(identity);
let activeInterval: ReturnType<typeof setInterval> | null = null;
const activeTimer = ref<number | null>(null);

const type: ComputedRef<'email' | 'phone' | 'something'> = computed(() => {
  if (isEmail(identity.value)) {
    return 'email';
  }

  if (isPhone(identity.value)) {
    return 'phone';
  }

  return 'something';
});

function startResendTimer() {
  stopResendTimer();
  activeTimer.value = 120; // seconds
  activeInterval = setInterval(() => {
    if (typeof activeTimer.value !== 'number') {
      return;
    }
    activeTimer.value--;
    if (activeTimer.value <= 0) {
      stopResendTimer();
    }
  }, 1000); // every second
}

function stopResendTimer() {
  if (activeInterval) {
    clearInterval(activeInterval);
  }
  activeInterval = null;
  activeTimer.value = null;
}

onMounted(async () => {
  try {
    await reSendVerificationCode(identity.value);
    success(t('otp').toString(), t('otpCodeSent').toString());
  } catch (err) {
    if (/Already Verified User/.test((err as CombinedError).message)) {
      startResendTimer();
      return;
    }

    if (/here is no user for this mobile number/.test((err as CombinedError).message)) {
      error('phone', 'There is no user for this mobile number');
      // close the modal
      emit('close');
      return;
    }

    error(t('resendError').toString(), (err as Error).message.replace('[GraphQL] ', ''));
  }
});

async function handleResendCode() {
  try {
    await reSendVerificationCode(identity.value);
    success(t('otp').toString(), t('otpCodeSent').toString());
    startResendTimer();
  } catch (err) {
    error(t('resendError').toString(), (err as Error).message.replace('[GraphQL] ', ''));
    stopResendTimer();
  }
}

function onEditPhoneNumberSubmit(phone: string) {
  try {
    state.value = 'ENTER_CODE';
    identity.value = phone;
  } catch (err) {
    if (/A customer exists with the new phone number\./.test((err as CombinedError).message)) {
      error('phone', 'A customer exists with the new phone number.');
    }
  }
}

watch(phoneVerification, async value => {
  if (!value) {
    return;
  }

  try {
    await verifyOtp(value, !props.validationOnly /** force login */);
    emit('success', value);
  } catch (e) {
    if ((e as Error)?.message === ExceptionsMap.otpVerification.wrongOtp?.key) {
      error(type.value, 'Make sure you typed a valid OTP');
      touchedOtp.value = false;
      invalidOtp.value = true;
      return;
    }
    error('error', (e as CombinedError).message);
    touchedOtp.value = false;
    invalidOtp.value = true;
    emit('error');
  }
});
</script>

<style lang="postcss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.transparent-selection::selection {
  background: transparent;
}

.otp-input-container {
  max-width: 425px;
  @screen md {
    max-width: unset;
  }
}

button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
</style>

<i18n>
{
  "en": {
    "title": "Verify {type}",
    "number": "Number",
    "email": "Email",
    "phone": "Phone Number",
    "sentTo": "A code was sent to {identity}, enter the code below",
    "notYourPhone": "Not your phone number? ",
    "changePhoneNumber": "Change Phone Number",
    "didntReceiveCode":"Didn't receive code?",
    "resendCode": "Resend Code",
    "invalidOtp": "Invalid OTP",
    "changePhoneNumberDescription": "Phone number is required to receive a code, inorder to verify your account. "
  },
  "ar": {
    "title": "يرجى التحقق من ${type}",
    "number": "رقم الهاتف",
    "email": "البريد الألكتروني",
    "phone": "رقم الهاتف",
    "sentTo": "تم إرسال رمز التحقق إلى {identity}، أدخل الرمز أدناه",
    "notYourPhone": "ليس رقم هاتفك؟ ",
    "changePhoneNumber": "تغيير رقم الهاتف",
    "didntReceiveCode":"لم تتلقى الرمز؟",
    "resendCode": "إعادة إرسال الرمز",
    "invalidOtp": "رمز التحقق غير صحيح",
    "changePhoneNumberDescription": "يجب أن يكون رقم الهاتف متاحًا لتلقي رمز التحقق، للتحقق من حسابك. "
  }
}
</i18n>
