<template>
  <div id="app" class="h-screen w-full flex flex-col bg-page">
    <header
      class="[ container max-auto ] [ pt-8 md:pt-10 py-4 md:pb-8 ] [ px-5 md:px-0 ] w-full flex items-center justify-center rounded-b-3xs bg-white md:bg-transparent shadow-custom-6 md:shadow-none z-[1]"
    >
      <div class="w-full flex md:flex-row flex-col gap-y-5 md:gap-y-0 justify-center md:justify-between items-center">
        <div class="inline-flex self-end text-base items-center gap-x-2 text-primary-800">
          <svg-icon name="shield" class="w-4 h-4 md:w-5 md:h-6" />
          {{ t('secureCheckout') }}
        </div>

        <AppLink to="/" class="max-w-fit order-first md:absolute top-10 left-0 right-0 mx-auto">
          <svg-icon name="logo" class="h-5 md:w-full md:h-8" alt="Brand Logo" />
        </AppLink>

        <div class="hidden md:inline-flex items-center gap-x-10 text-primary-800">
          <AppLink to="/" class="flex items-center gap-x-2">
            <svg-icon name="chat" class="w-5 h-6" />

            {{ t('chatSupport') }}
          </AppLink>
          <svg-icon name="shield" class="w-5 h-6" />
          <a
            class="text-sm"
            :class="[$i18n.locale === 'en' ? 'font-body-ar font-medium' : 'font-body']"
            :href="switchLocalePath($i18n.locale === 'en' ? 'ar' : 'en')"
          >
            {{ t('switchLang') }}
          </a>
        </div>
      </div>
    </header>

    <div id="after-header" />
    <div id="after-header-fade" transition="TransitionFade" />
    <div id="after-header-slide" transition="TransitionSlideUp" />

    <main class="flex-grow bg-page">
      <slot />
    </main>

    <ModalVerifyOtp />

    <AlertGroup />

    <footer
      class="z-0 bg-primary-800 rounded-t-2xs md:py-7 text-white flex flex-col items-center justify-center text-sm text-center lg:text-left"
    >
      <div class="bg-black-500 md:bg-transparent w-full flex flex-col items-center md:justify-center px-5 md:px-0">
        <AppLink to="/" class="md:hidden self-start mt-5 flex gap-x-2">
          <svg-icon name="chat-dimmed" class="w-5 h-6" />

          {{ t('chatSupport') }}
        </AppLink>
        <i18n-t tag="p" keypath="privacyNote" class="text-start md:text-center mt-6 mb-8 md:mt-0 text-sm text-white">
          <template #privacy>
            <a href="/privacy-policy" target="_blank" rel="noopener" class="underline uppercase text-white">
              {{ t('privacyPolicy') }}
            </a>
          </template>
        </i18n-t>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import { type Organization, type WebSite } from 'schema-dts';

const { t } = useI18n({
  useScope: 'local',
});

const switchLocalePath = useSwitchLocalePath();

useSeoLinks();

const {
  public: { apiUrl: url },
} = useRuntimeConfig();

// https://developers.google.com/search/docs/data-types/sitelinks-searchbox
const websiteWithSearchAction: WebSite = {
  '@type': 'WebSite',
  url,
  potentialAction: {
    '@type': 'SearchAction',
    target: `${url}/search?q={search_term_string}`,
    query: 'required name=search_term_string',
  },
};

const org: Organization = {
  '@id': url,
  '@type': 'Organization',
  // TODO: Organization name
  name: 'Unique',
  url,
  logo: `${url}/img/logo.png`,
  sameAs: [
    // TODO: Any social links to facebook/twitter/whatever
  ],
};

useJsonld({
  '@context': 'https://schema.org',
  '@graph': [websiteWithSearchAction, org],
});
</script>
<style lang="postcss" scoped>
@screen md {
  .header-container {
    display: grid;
    grid-template-columns: 1fr 170px 1fr;
  }
}
</style>
<i18n>
{
  "en": {
    "privacyNote": "Your entire personal data is protected and will be treated in accordance with our {privacy}",
    "privacyPolicy": "Privacy Policy",
    "secureCheckout": "Secure Checkout",
    "switchLang": "عربي",
    "changeLanguage": "Change language",
    "customerService": "Customer Service",
    "chatSupport": "Chat Support"
  },
  "ar": {
    "privacyNote": "تتم حماية بياناتك الشخصية بالكامل وسيتم التعامل معها وفقًا لموقعنا {privacy}",
    "privacyPolicy": "سياسة الخصوصية",
    "secureCheckout": "الدفع الآمن",
    "switchLang": "English",
    "changeLanguage": "تغيير اللغه",
    "customerService": "دعم العملاء",
    "chatSupport": "دعم الدردشة"
  }
}
</i18n>
